import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as RiIcons from "react-icons/ri";
import * as HiIcons from "react-icons/hi";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  Button,
  Collapse,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import AdminFooter from "./AdminFooter";
import "./AdminContent.css";
const AdminContent = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const [openOne, setOpenOne] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [openThree, setOpenThree] = useState(false);

  const [clickState, setClickState] = useState();

  const ToggleClass = (index) => {
    setClickState(index);
  };

  return (
    <div className="d-flex admin__dashboard">
      <nav className={`admin_sidebar ${toggleNav ? "close" : ""}`}>
        <header className="mt-4 mb-5">
          <Link to="/" target="_blank" className="text-decoration-none">
            <div className="mx-4 image-text d-none d-md-flex align-items-center justify-content-between">
              <div className="text logo_img_wrapper">
               
              </div>
            </div>
          </Link>

          <AiIcons.AiOutlineMenu
            className="bx bx-chevron-right toggle d-none d-md-block"
            onClick={() => setToggleNav(!toggleNav)}
          />
        </header>

        <div className="menu-bar">
          <div className="menu">
            <ul className="menu-links ps-0">
              <li
                className={`nav-link ${clickState === 1 ? "activeLink" : ""}`}
              >
                <Link to="" onClick={() => ToggleClass(1)}>
                  <AiIcons.AiOutlineHome className="icon" />
                  <span className={`text nav-text `}>Home</span>
                </Link>
              </li>
              <li
                className={`nav-link ${clickState === 2 ? "activeLink" : ""}`}
              >
                <Link to="" onClick={() => ToggleClass(2)}>
                  <BsIcons.BsInbox className="icon" />
                  <span className={`text nav-text `}>Inbox</span>
                </Link>
              </li>

              <li
                className={`nav-link border-bottom ${
                  clickState === 3 ? "activeLink" : ""
                }`}
                title="Notifications"
              >
                <Link to="" onClick={() => ToggleClass(3)}>
                  <MdIcons.MdNotificationsNone className="icon" />
                  <span className={`text nav-text `}>Notifications</span>
                </Link>
              </li>
             
              <li
                className={`nav-link border-bottom ${
                  clickState === 4 ? "activeLink" : ""
                }`}
              >
                <Link to="" onClick={() => ToggleClass(4)}>
                  <BiIcons.BiPencil className="icon" />
                  <span className="text nav-text text-uppercase">
                    Project Drafts
                  </span>
                </Link>
              </li>

              {/* <li
                className="nav-link d-flex align-items-center cursor"
                id="latestProjects"
                onClick={() => cusToggole("latestProjects")}
              >
                <div className="d-flex justify-content-between align-items-center py-2">
                  <FaIcons.FaProductHunt className="icon" />
                  <div className="text nav-text dropdown_menu">
                    Latest Projects
                    <span>
                      <IoIcons.IoChevronDownOutline
                        className={` ms-4 ${open ? "rotate_icon" : ""}`}
                      />
                    </span>
                  </div>
                </div>
              </li>

              <div className="dropdown_menu_item w-100">
                <Collapse id="latestProjectsCollapse" in={false}>
                  <div id="">
                    <Link to="latest-projects/category">
                      <p className="text nav-text mb-0  py-2">
                        Project Category
                      </p>
                    </Link>
                    <Link to="latest-project">
                      <p className="text nav-text mb-0 py-2">All Projects</p>
                    </Link>
                  </div>
                </Collapse>
              </div> */}

              <li
                className={`nav-link border-bottom ${
                  clickState === 5 ? "activeLink" : ""
                }`}
              >
                <Link
                  to=""
                  onClick={() => {
                    ToggleClass(5);
                  }}
                >
                  <AiIcons.AiOutlinePlusCircle className="icon" />
                  <span className="text nav-text text-uppercase">
                    Create New Project
                  </span>
                </Link>
              </li>
              <li
                className={`nav-link border-bottom ${
                  clickState === 6 ? "activeLink" : ""
                }`}
              >
                <Link to="" onClick={() => ToggleClass(6)}>
                  <HiIcons.HiOutlineTemplate className="icon" />
                  <span className="text nav-text text-uppercase">
                    Templates
                  </span>
                </Link>
              </li>

              <li className="nav-link">
                <Link to="" className="user_logout">
                  <BiIcons.BiLogOut className="icon" />
                  <span className="text nav-text text-uppercase">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="rightbar">
        <section style={{ marginBottom: "100px" }}>
          <Outlet />
        </section>
      </section>
      <AdminFooter />
    </div>
  );
};

export default AdminContent;
