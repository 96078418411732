import React, { useState } from "react";
import { Form, NavDropdown } from "react-bootstrap";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";

const AdminDashboardNavbar = ({ admin_profile_logo }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="topbar">
      <div className="row gx-0 justify-content-end searchbox ">
        <div className="col-md-10 col-xl-4">
          <div className="row gx-0">
            <div className="col-md-7 col-xl-8">
              <Form>
                <Form.Group className="position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Search for projects and items"
                    className="form_control"
                  />
                  <BsIcons.BsSearch size="1.2em" className="search_icon" />
                </Form.Group>
              </Form>
            </div>
            <div className="col-md-5 col-xl-4 flex_around">
              <AiIcons.AiOutlineInfoCircle size="1.5em" />
              <BsIcons.BsGift size="1.5em" />

              <NavDropdown
                onClick={() => setShow(!show)}
                className="admin_profile_dropdown position-relative"
                id="collasible-nav-dropdown"
                title={<div className={`admin_profile_logo`}></div>}
              >
                <NavDropdown.Item as={Link} to="#" className=" border-bottom">
                  <div className="d-flex align-items-center">
                    <div className={`admin_profile_logo me-3 mb-3`}></div>
                    <div className="mb-3">
                      <h4 className="mb-0 cl-333">TRODAD</h4>
                      <p className="mb-0">trodad@gmail.com</p>
                    </div>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className="text-uppercase t-fs-18 cl-333"
                >
                  organizations &nbsp;(1)
                  <span className="ms-3">
                    {" "}
                    <AiIcons.AiOutlinePlus />{" "}
                  </span>
                </NavDropdown.Item>
                <div className="arrow-up"></div>

                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Personal Settings
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Admin Control
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Invoices
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Payment History
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="#"
                  className=" t-fs-18 cl-333 mb-1 border-bottom"
                >
                  Payment History
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#" className=" t-fs-18 cl-333">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardNavbar;
