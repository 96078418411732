import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Admin from "./Pages/Admin/Admin";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";
import { createContext, useState } from "react";
export const UserContext = createContext();

function App() {
  const [modalShow, setModalShow] = useState(true);

  return (
    <UserContext.Provider value={{ modalShow, setModalShow }}>
      <BrowserRouter>
        <Routes>
          <Route path="admin" element={<Admin />}>
            <Route index element={<AdminDashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
