import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";

import "./admindashboard.css";
import AdminDashboardNavbar from "../../../Components/AdminDashboardNavbar";

const AdminDashboard = () => {
  return (
    <div className="">
      <div className="body-wrapper">
        <AdminDashboardNavbar admin_profile_logo="admin_profile_logo" />
        <div className="main_section">
          <div className="row py-3">
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <FaIcons.FaWindowRestore className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Orders</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">1</h1>
                    <p>
                      <Link to="all-orders" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <FaIcons.FaListAlt className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Product Category</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">2</h1>
                    <p>
                      <Link to="categories" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <SiIcons.SiBrandfolder className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Brands</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">3</h1>
                    <p>
                      <Link to="brands" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <MdIcons.MdGroups className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>All Customers</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">4</h1>
                    <p>
                      <Link to="all-customers" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="dashboard_card mb-3">
                <div className="widget">
                  <div className="widget-icon">
                    <FaIcons.FaProductHunt className="w-icon" />
                  </div>
                  <div className="widget-content">
                    <h6 className="text-uppercase text widget-title">
                      <strong>Products</strong>
                    </h6>
                    <h1 className=" text-dark font-weight-bold">5</h1>
                    <p>
                      <Link to="products" className="btn">
                        View All
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
